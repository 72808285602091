import React from 'react';
import logoFooter from '../images/logo_footer.svg';
import btLinkedinFooter from '../images/bt_linkedin_footer.svg';
import btGlobalFooter from '../images/bt_global_footer.svg';
import btInstagramFooter from '../images/bt_instagram_footer.svg';
import * as footerStyles from '../styles/footer.module.css';
import { Link } from 'react-router-dom';
import { OutboundLink } from 'react-ga'


const Footer = () => {
  return (
    <div className={footerStyles.flex_container_fluid}>
      <div className={footerStyles.flex_container}>
        <div className={footerStyles.footer_container}>
          <div className={footerStyles.footer_logo}>
            <img src={logoFooter} alt='Quillok' />
            <p>
            Helping businesses launch fast <br /> and grow strong. 
            </p>
          </div>

          <div className={footerStyles.footer_contact}>
            <div className={footerStyles.contact_title}>Contact</div>
            <OutboundLink
              eventLabel="click_on_email"
              to="mailto:hello@quillok.com"
              target="_blank"
              className={footerStyles.contact_mail}
            >
              How can we help?
            </OutboundLink>
            <p className={footerStyles.contact_address}>Montevideo, Uruguay.</p>
          </div>

          <div className={footerStyles.footer_joinUs}>
            <div className={footerStyles.joinUs_title}>Join Us</div>
            <OutboundLink
              eventLabel="click_on_careers"
              to="https://3aovymwpm25.typeform.com/to/zrH7Qc2A"
              target="_blank"
              className={footerStyles.joinUs_careers}
            >
              Careers
            </OutboundLink>
          </div>

          <div className={footerStyles.footer_beSocial}>
            <div className={footerStyles.beSocial_title}>Be Social</div>
            <div className={footerStyles.beSocial_rrss}>
              <OutboundLink
                eventLabel="click_on_linkedin"
                to="https://www.linkedin.com/company/quillok"
                target="_blank"

              >

                <img src={btLinkedinFooter} alt='LinkedIn' />
              </OutboundLink>
              <OutboundLink
                eventLabel="click_on_instagram"
                to="https://www.instagram.com/quillokteam/"
                target="_blank"

              >
                <img src={btInstagramFooter} alt='Instagram' />
              </OutboundLink>
            </div>
          </div>

          <iframe className={footerStyles.badge} width="92" height="99" style={{ border: 0 }} src="https://shareables.clutch.co/share/badges/1818606/1476?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch App Development Company Uruguay 2023" />
        </div>
      </div>
    </div >
  );
};

export default Footer;
