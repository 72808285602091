export const projects = [
  {
    num: '01',
    title: 'ShelfSet',
    id: 1,
    contentShort: ` is a complete tool for shelf space analysis. Leveraging AI technology, users can obtain critical data on how the shelves are laid out, so they can take action on how to maximize and optimize their operations.`,
    content1: `ShelfSet is a complete tool for shelf space analysis. Leveraging AI technology, users can obtain critical data on how the shelves are laid out, so they can take action on how to maximize and optimize their operations.`,
    content2: `We partnered with the ShelfSet team to help them build both native iOS and Android apps from the ground up.`,
    content3: ``,
    content4: `Technologies used:`,
    content5: `Swift | Kotlin | MLCore | TensorFlow Lite`,
  },
  {
    num: '02',
    title: 'FaceFwd',
    id: 2,
    contentShort: ` is the first platform that allows you to see your friends reacting to the things you share with them! See reactions in real-time, on your time.`,
    content1: `Facefwd is the first platform that allows you to see your friends reacting to the things you share with them! See reactions in real-time, on your time.`,
    content2: `Its founder, Shakur, approached us looking to give it a fresh new look to the UI and change the UX to boost user retention.`,
    content3: ``,
    content4: `Technologies used:`,
    content5: `Swift | Ruby on Rails`,
  },
  {
    num: '03',
    title: 'Recordify',
    id: 3,
    contentShort: ` sending audios on Slack has never been easier with Recordify! You just hit a button and are ready to go.`,
    content1: `Sending audios on Slack has never been easier with Recordify! You just hit a button and are ready to go.`,
    content2: `With over 12,000 downloads and more than 2,000 monthly active users, it is still the best way to boost your productivity by sending voice messages instantly through Slack. A different way to communicate, experiencing how minimalist visuals, hide simplicity reimagined.`,
    content3: ``,
    content4: `Technologies used:`,
    content5: `Swift | Slack Services`,
  },
  {
    num: '04',
    title: 'MyCrowd',
    id: 4,
    contentShort: ``,
    content1: `MyCrowd has the mission to encourage people to go outside and meet up, get off social media and make it easier to plan for events together.`,
    content2: `We helped Saajan, MyCrowd CEO and Founder, get the app off the ground leveraging technologies like React Native and AWS for a fast time to market, as well as scalability.`,
    content3: ``,
    content4: `Technologies used:`,
    content5: `React Native | AWS Amplify | GraphQL`,
  },
  {
    num: '05',
    title: 'Roksia',
    id: 5,
    contentShort: ``,
    content1: `Roksia allows content creators to showcase their work on a platform designed for them.`,
    content2: `With Roksia, they can not only put their content out there but also share their skills and leverage the messaging capabilities to attract potential clients.x`,
    content3: `We helped Paul and his team push the development of the mobile apps forward.`,
    content4: `Technologies used:`,
    content5: `React Native | Firebase Firestore`,
  },
  {
    num: '06',
    title: 'Entri',
    id: 6,
    contentShort: ``,
    content1: `We have all struggled setting up DNS, it is messy and not a smooth process at all. Entri’s goal is to build a digital product that makes that experience effortless.`,
    content2: `We partnered with Abe, Entri’s Founder and CEO and the ++hellohello design agency to develop the web application from scratch.`,
    content3: ``,
    content4: `Technologies used:`,
    content5: `ReactJS | Material-UI | Sass | WebSocket`,
  },
  {
    num: '07',
    title: 'NowPerformance',
    id: 7,
    contentShort: ``,
    content1: `Now Performance has an important mission behind it: to inspire kids to move.`,
    content2: `Their mobile app that tracks and stores metrics from smartwatches. The goal is to convert this user data to points that later can be redeemed for products in an e-commerce store.x`,
    content3: `We partnered with the NP team to develop their mobile app leveraging React Native and custom native modules for wearable support.`,
    content4: `Technologies used:`,
    content5: `React Native | AWS amplify | SQLite | TypeORM`,
  },
  {
    num: '08',
    title: 'LingoZing',
    id: 8,
    contentShort: ``,
    content1: `We have all beat our brains out trying to learn a new language. LingoZING is the first audiovisual learning comic book app.`,
    content2: `Their mobile app allows you to read and listen to your favorite comics, graphic novels, picture books, anime and manga while you get that new language under your belt.`,
    content3: `We helped Its CEO and Founder Kyra with the development of their iOS Mobile application to the next level.`,
    content4: `Technologies used:`,
    content5: `Swift`,
  },
];
