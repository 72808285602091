import React from 'react';
import Layout from '../components/Layout';
import Hero from './Hero';
import Services from './Services';
import Testimonials from './Testimonials';
import Portfolio from './Portfolio';
import Process from './Process';
import Projects from './Projects';
import Founders from './Founders';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

const trackingId = "G-1R7HY9QF96";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path='/'>
            <Hero />
            <Services />
            <Process />
            <Portfolio />
            <Testimonials />
            {/* <Founders /> */}
          </Route>
          <Route path='/portfolio'>
            <Projects />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
